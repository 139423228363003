import Login from 'templates/Login';
import { getLayout } from 'layouts/Default';
import { BackgroundColor } from 'utils/ColorUtils';
import { NextPageWithLayout } from './_app';

const LoginPage: NextPageWithLayout = () => (
  <div className="py-12 md:py-16 lg:py-20 mt-10 mb-40">
    <Login />
  </div>
);

LoginPage.backgroundColor = BackgroundColor.XLightBlue;
LoginPage.getLayout = getLayout;

export default LoginPage;
